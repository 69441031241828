import { atom, selector } from 'recoil'
import { TextAttributes } from '../interfaces/TextAttribute'
import EnglishText from '../translation/en'
import { AvaliableLanguages } from '../enums/avaliable-language.enum'
import { recoilPersist } from 'recoil-persist'
import GermanText from '../translation/de'

const { persistAtom } = recoilPersist()
export const userLanguageChoiceState = atom<AvaliableLanguages>({
  key: 'userLanguageChoice',
  default: AvaliableLanguages.German as AvaliableLanguages,
  effects_UNSTABLE: [persistAtom],
})

export const textState = selector({
  key: 'text',
  get: ({ get }): TextAttributes => {
    const userChoice = get(userLanguageChoiceState)
    const initialText: TextAttributes = EnglishText

    if (userChoice === AvaliableLanguages.German) {
      return GermanText
    }

    return initialText
  },
})
