/* eslint-disable max-len */
import { TextAttributes } from '../interfaces/TextAttribute'

const GermanText: TextAttributes = {
  home_explore_events: 'Ballon-Events erkunden',
  home_balloon_events_description:
    'Balloon-Events macht die Veranstaltungen in der Umgebung von Nutzer:innen mit digitalen Karten und Augmented Reality sichtbar. Wir visualisieren nicht nur, wo, sondern auch welche Art und wann die Veranstaltungen stattfinden.',
  home_register_new_event: 'Neue Ereignisse registrieren',
  home_all_events: 'Alle Veranstaltungen',
  home_app_store: 'Play Store',
  home_play_store: 'App Store',
  back_to_top: 'Nach oben',

  filter_option_grid_view: 'Kartensicht ',
  filter_option_list_view: 'Listenansicht',
  filter_option_map_view: 'Map-Ansicht',
  filter_option_category_placeholder: 'Kategorie',
  filter_modal_date_selection: 'Datumsauswahl',
  filter_modal_order_adjustment: 'Anpassung der Reihenfolge',
  filter_modal_source_selection: 'Auswahl der Quelle',
  filter_modal_category_selection: 'Auswahl der Kategorie',
  filter_modal_view_selection: 'Auswahl der Ansicht',
  filter_modal_date_picker_placeholder: 'Anfangsdatum - Enddatum',

  steps_template_btn_register: 'Registrieren',
  steps_template_btn_prev: 'Vorher',
  steps_template_btn_next: 'Nächste',
  steps_template_btn_edit: 'Bearbeiten',
  steps_template_btn_publish: 'Erstellen',
  steps_template_working_in_progress:
    'Erstellung von Veranstaltungen in Arbeit ',
  steps_template_navigation_step: 'navigiert zu Schritt ',
  steps_template_main_info: 'Wichtige Infos',
  steps_template_necessary_info: 'Erforderliche Infos',
  steps_template_general_info: 'Allgemeine Infos',
  steps_template_detail_info: 'Ausführliche Infos',
  steps_template_event_characteristics: 'Eigenschaft der Veranstaltung',

  all_events_no_results: 'Keine Ereignisse passen zu Ihrer Filterauswahl',
  event_title: 'Titel',
  event_title_placeholder:
    'Bitte geben Sie einen beschreibenden Titel für Ihre Veranstaltung an.',
  event_description: 'Beschreibung',
  event_source: 'Quelle',
  event_time: 'Datum',
  event_image: 'Bild',
  event_description_info:
    'Beschreiben Sie, worum es bei Ihrer Veranstaltung geht. Wer tritt auf oder wird anwesend sein. Schreiben Sie alles auf, was potenzielle Teilnehmer interessieren könnte.',
  event_description_placeholder:
    'Geben Sie eine vollständige Beschreibung der Veranstaltung ein.',
  event_teaser: 'Teaser',
  event_teaser_info:
    'Schreiben Sie einen kurzen Text, der einen kurzen Einblick in die Veranstaltung gibt und neugierig macht',
  event_teaser_placeholder: 'Geben Sie hier Hashtags ein.',
  event_hash_tag: 'Suchen Sie nach Hashtags für Ihre Veranstaltung!',
  event_hash_tag_placeholder: 'Geben Sie hier Hashtags ein...',
  event_name_placeholder: 'Name der Veranstaltung',
  event_gallery: 'Event Galerie',
  event_gallery_info:
    'Bilder machen Veranstaltungen attraktiv. Wählen Sie also ein paar Bilder aus, die Ihre Veranstaltung beschreiben. Das erste Bild ist das Titelbild.',
  drag_drop_image: 'Ziehen Sie das Bild hierher.',
  event_title_info:
    'Bitte geben Sie einen beschreibenden Titel für Ihre Veranstaltung an.',
  event_start_time: 'Startzeit: ',
  event_start_time_info: 'Wann beginnt Ihre Veranstaltung?',
  event_end_time: 'Endzeit: ',
  event_end_time_info: 'Wann endet Ihre Veranstaltung?',
  event_primary_category: 'Primärkategorie',
  event_primary_category_info:
    'Bitte wählen Sie für Ihre Veranstaltung die Kategorie aus, die am ehesten dazu passt.',
  event_primary_category_placeholder: 'Primärkategorie auswählen',
  event_location_name: 'Name des Standorts',
  event_location_name_placeholder: 'z.B. Museum',
  event_location_direction: 'Richtung des Standorts',
  event_location_direction_info:
    'Beschreiben Sie, wie Sie den Ort erreichen können. Wenn Sie einen Link zu einer Karte haben, können Sie ihn auch hier hinzufügen.',
  event_location_direction_placeholder: 'Beschreiben Sie den Weg zum Standort.',
  event_other_category: 'sonstige Kategorien',
  event_other_category_info:
    'Bitte wählen Sie weitere Kategorien aus, die Ihre Veranstaltung auch beschreiben können.',
  event_other_category_placeholder: 'Kategorien auswählen',

  apply_button: 'Anwenden',
  reset_button: 'Zurücksetzen',
  date_start: 'Start: ',
  date_end: 'Ende: ',
  event_create: 'Erstellt:',
  event_last_modified: 'Zuletzt geändert ',

  order_by: 'Sortieren nach',
  search_event_placeholder: 'Suche nach Ereignisnamen.',
  start_date_tooltip: 'Startzeit auf jetzt setzen',
  clear_start_date_tooltip: 'Lösche die Startzeit',
  end_date_tooltip: 'Endzeitpunkt auf einen Monat ab jetzt setzen',
  clear_end_date_tooltip: 'Endzeitpunkt löschen',
  create_base_on_existed_tooltip: 'Auf Basis dieses Ereignisses erstellen.',
  map_drag_marker_tooltip:
    'Verschieben Sie den Geo-Marker, auf den Veranstaltungsort.',
  map_choose_location: 'Ausgewählter Standort: ',
  menu_home: 'Startseite',
  menu_all_events: 'Ereignisliste',
  menu_register_event: 'Ereignis erstellen',

  event_sum: 'Summe : ',
  search_event: 'Ereignisse suchen',
  register_event: 'Ereignis erstellen',
  event_characteristics: 'Merkmale der Veranstaltung',
  event_contact: 'Kontakt',

  error_event_fetching: 'Ereignisse abrufen',
  error_meta_data_fetching: 'Metadaten abrufen',
  error_function_not_implemented: 'Funktion nicht implementiert.',
  link_input_error: 'Link muss beginnen mit',
  error_start_time: 'Die Endzeit kann nicht vor der Startzeit liegen',
  error_end_time: 'Die Endzeit kann nicht gleich der Startzeit sein',
  searched_result: 'Gesuchtes Ereignis wurde',
  error_not_found: 'nicht gefunden.',
  error_event_not_exist:
    'Die Veranstaltung, die Sie suchen, existiert nicht. Prüfen Sie andere Veranstaltungen mit unsere fabelhafte API.',
  error_register_own_event:
    'Oder erstellen Sie hier Ihre eigene Veranstaltung.',
  error_primary_category_handling: 'Bitte wählen Sie eine Primärkategorie aus!',
  error_email_require: 'Fehlende Infos',
  error_missing_info: 'E-Mail Adresse ist erforderlich',
  toast_creates_event: 'Erstellung von Veranstaltungen',
  toast_copied_to_the_clip_board:
    'Der Link wurde in die Zwischenablage kopiert!',
  toast_edit_event: 'Veranstaltung editieren',
  error_creates_event_try_again:
    'Ereigniserstellung fehlgeschlagen, bitte versuchen Sie es später noch einmal!',
  error_token_expire: 'Token abgelaufen',
  error_token_expire_msg: ' Umleitung zur Anmelde',
  error_user_permission: 'Benutzererlaubnis',
  error_no_log_in:
    'Sie müssen sich anmelden, um auf die Seite zugreifen zu können.',

  user_registeration: 'Benutzerregistrierung',
  upload_limit: 'Upload-Limit',
  photo_over_max: 'Es sind nur maximal 10 Bilder erlaubt',
  photo_upload: 'Foto hochladen',
  success_toast: 'Erfolgreich operieren!',
  success_creates_event: 'Veranstaltung erfolgreich erstellt!',
  success_edit_event: 'Veranstaltung erfolgreich editiert!',
  toast_notice: 'Hinweis',
  info_toast_not_enough_data:
    'Die Zeitauswahl kann entfernt werden, um genügend Daten zu sehen.',

  log_in: 'Anmelden',
  log_out: 'Abmelden',

  event_register_step_main_info: 'Hauptinfo',
  event_register_step_descrption: 'Beschreibung',
  event_register_step_details: 'Details',
  event_register_step_characteristics: 'Merkmale',
  event_register_step_contact_info: 'Kontaktinformationen',
  event_register_step_event_preview: 'Veranstaltungsvorschau',

  menu_indicator: 'Menü',
  next_indicator: 'Weiter',
  last_modified: 'Zuletzt geändert',
  not_provided: 'Nicht vorhanden!',
  none_selected: 'keine ausgewählt',
  no_other_category: 'keine anderen Kategorien',
  only_coordinate_provided: 'Es werden nur Koordinaten bereitgestellt!',

  characteristic_is_prepare_for_rain: 'Veranstaltungsort für Regen vorbereitet',
  characteristic_pet_friendly: 'Haustierfreundlich',
  characteristic_kids_friendly: 'Kinderfreundlich',
  characteristic_parking_avaliable: 'Parkplätze vorhanden',
  characteristic_alcohol_allowed: 'Alkohol erlaubt',
  characteristic_public_transportation: 'Öffentliche Verkehrsmittel',
  characteristic_qualified_data: 'Qualifizierte Daten',
  characteristic_unqualified_data: 'Unqualifizierte Daten',
  characteristic_qualified_data_info:
    'Kreuzen Sie an, ob die Informationsquelle korrekt und überprüft ist',
  charateristic_indoor: 'Innenbereich',
  charateristic_outdoor: 'Außenbereich',
  charateristic_food: 'Essen',
  charateristic_beverage: 'Getränke',
  charateristic_wheelchair: 'Rollstuhl',
  charateristic_stroller: 'Kinderwagen',
  characteristic_food_variety: 'Verfügbare Lebensmittelvariationen',
  charateristic_no_food_selection: 'Keine Lebensmittelsorten ausgewählt',
  charateristic_no_target_group_selection: 'Keine Zielgruppe ausgewählt',

  attribute_title_ticket_price: 'Ticketpreis',
  attribute_ticket_free: 'Gratis',
  attribute_age_no_limit: 'Kein Limit',
  attribute_title_age_range: 'Altersgruppe',
  attribute_title_attendee_limit: 'Teilnehmerlimit',
  attribute_title_volume: 'Volumen',
  attribute_title_social_ability: 'Gelegenheit zur Geselligkeit',
  attribute_social_ability_info:
    'Ist es einfach, bei Ihrer Veranstaltung neue Leute kennen zu lernen?',
  attribute_public_transport_info:
    'Können Sie die Veranstaltung mit öffentlichen Verkehrsmitteln erreichen?',
  attribute_title_rare: 'Selten',
  attribute_title_intense: 'Intensiv',
  attribute_title_involvement: 'Beteiligung',
  attribute_involvement_info:
    'Hier können Sie festlegen, inwieweit Ihre Besucher selbst aktiv an der Veranstaltung teilnehmen können. Vielleicht können sie selbst Übungen machen, mitspielen oder mittrainieren.',
  attribute_title_passive: 'Passiv',
  attribute_title_active: 'Aktiv',
  attribute_title_atmosphere: 'Atmosphäre',
  attribute_atmosphere_info:
    'Hier können Sie angeben, ob es sich um eine kontemplative, nachdenkliche Veranstaltung handelt, die Ihnen hilft, zur Ruhe zu kommen, oder ob es sich um eine lebhafte Veranstaltung handelt, bei der es darum geht, mit anderen in Kontakt zu kommen.',
  attribute_title_contemplative: 'Kontemplativ',
  attribute_title_entertaining: 'Unterhaltsam',
  attribute_title_target_group: 'Zielgruppe',
  attribute_title_venue_options: 'Veranstaltungsort-Option',
  attribute_title_food_beverages: 'Essen und Getränke',
  attribute_title_accessibility: 'Zugänglichkeit',
  attribute_ticket_info:
    'Bitte geben Sie die Preisspanne vom billigsten bis zum teuersten Ticket an',
  attribute_age_info:
    'Bitte geben Sie an, für welche Altersgruppe die Veranstaltung gedacht ist',
  attribute_attendee_limit_info:
    'Wenn es eine Teilnehmerzahl gibt, tragen Sie diese bitte hier ein',
  attribute_volume_info:
    'Hier können Sie angeben, mit welchem Lärmpegel die Teilnehmer rechnen können.',
  attribute_venue_options_info:
    'Wählen Sie, ob die Veranstaltung drinnen oder draußen stattfinden soll',
  attribute_accessibility_info:
    'Bitte kreuzen Sie das entsprechende Feld an, wenn die Veranstaltung für Kinderwagen und/oder Rollstühle zugänglich ist.',

  contact_link_title_ticket: 'Ticket Website',
  contact_link_title_website: 'Website Link',
  contact_link_title_email: 'Email',
  contact_link_title_phone: 'Telefonnummer',
  contact_link_title_facebook: 'Facebook Link',
  contact_link_title_ins: 'Instagram Link',
  contact_link_title_google_map: 'Google Map Link',
  contact_link_title_twitter: 'Twitter Link',

  event_detail_no_image: 'keine Bilder hochgeladen!',
  event_detail_navigation: 'mehr Infos',
}

export default GermanText
