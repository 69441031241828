import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { ConfigurationsAttributes } from '../interfaces/ConfigurationsAttributes'
import { ListViewType } from '../enums/list-view-type.enum'

const { persistAtom } = recoilPersist()
const initialConfigurationsState = {
  showNavbar: true,
  newEventLocationProvided: false,
  is_Authenticated: false,
  allEventsListView: ListViewType.LISTVIEW,
  API_BASE: 'https://api.balloon-events.com/creator',
  placeHolder: false,
}
export const configurationsState = atom<ConfigurationsAttributes>({
  key: 'configurations',
  default: initialConfigurationsState,
  effects_UNSTABLE: [persistAtom],
})
