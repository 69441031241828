import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'
import { UserData, UserDataResponse } from '../interfaces/UserData'
import { UserDataModel } from '../models/UserDataModel'

const { persistAtom } = recoilPersist()
export const initialUserData: UserData = {
  bearer_token: '',
  timestamp: -1,
  email: '',
  ident: '',
  memberships: [],
  selectedBalloon: '',
  selectedIndex: 0,
  pageSession: '',
}

export const userDataResponseState = atom<UserDataResponse>({
  key: 'userDataResponse',
  default: initialUserData,
  effects_UNSTABLE: [persistAtom],
})

export const userDataState = selector({
  key: 'userData',
  get: ({ get }): UserData => {
    const userDataResponse: UserDataResponse = get(userDataResponseState)
    if (userDataResponse) {
      return UserDataModel.fromUserDataResponse(userDataResponse)
    }
    return initialUserData
  },
})
