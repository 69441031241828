export enum EventCategories {
  Other = 'other',
  Spiritual = 'spiritual',
  Market = 'market',
  Music = 'music',
  Culture = 'culture',
  Sport = 'sport',
  Culinary = 'culinary',
  Tradition = 'tradition',
  Knowledge = 'knowledge',
  UNDEFINED_CATEGORY = 'UNDEFINED_CATEGORY',
}
export enum EventCategoriesDE {
  Other = 'sonstige',
  Spiritual = 'spirituell',
  Market = 'markt',
  Music = 'musik',
  Culture = 'kultur',
  Sport = 'sport',
  Culinary = 'Kulinarisch',
  Tradition = 'Tradition',
  Knowledge = 'wissen',
  UNDEFINED_CATEGORY = 'UNDEFINED_CATEGORY',
}
