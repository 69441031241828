import { UserDataMemberships, UserDataResponse } from '../interfaces/UserData'

export class UserDataModel {
  constructor(
    public bearer_token: string,
    public timestamp: number,
    public email: string,
    public ident: string,
    public memberships: UserDataMemberships[],
    public selectedBalloon: string,
    public selectedIndex: number,
    public pageSession: string
  ) {}

  static fromUserDataResponse(response: UserDataResponse) {
    return new UserDataModel(
      response.bearer_token,
      Math.floor(Date.now() / 1000),
      response.email,
      response.ident,
      response.memberships,
      '',
      0,
      ''
    )
  }
}
