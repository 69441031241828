/* eslint-disable max-len */
import { TextAttributes } from '../interfaces/TextAttribute'

const EnglishText: TextAttributes = {
  home_explore_events: 'Explore Balloon-Events',
  home_balloon_events_description:
    '  Balloon-Events relies on and uses the intuitiveness of maps paired with augmented reality in order to enhance the amount and quality of the information you see. We not only visualize where but also what kind of and when events take place.',
  home_register_new_event: 'Register new event',
  home_all_events: 'All Events',
  home_app_store: 'Play Store',
  home_play_store: 'App Store',
  back_to_top: 'Back To Top',

  filter_option_grid_view: 'Card view ',
  filter_option_list_view: 'List view',
  filter_option_map_view: 'Map view',
  filter_option_category_placeholder: 'Category',
  filter_modal_date_selection: 'Date selection',
  filter_modal_order_adjustment: 'Order adjustment',
  filter_modal_source_selection: 'Source selection',
  filter_modal_category_selection: 'Category Selection',
  filter_modal_view_selection: 'View Selection',
  filter_modal_date_picker_placeholder: '    Start date   -   End date',

  steps_template_btn_register: 'Register',
  steps_template_btn_prev: 'Previous',
  steps_template_btn_next: 'Next',
  steps_template_btn_edit: 'Edit',
  steps_template_btn_publish: 'Publish',
  steps_template_working_in_progress: 'Event creation working in progress ',
  steps_template_navigation_step: 'Navigated to step ',
  steps_template_main_info: 'Main Info',
  steps_template_necessary_info: 'Necessary Info',
  steps_template_general_info: 'General Info',
  steps_template_detail_info: 'Detailed Info',
  steps_template_event_characteristics: 'Event Characteristics',

  all_events_no_results: 'No events fit your filter selection',
  event_title: 'Title',
  event_description: 'Description',
  event_source: 'Source',
  event_time: 'Time',
  event_image: 'Image',
  event_description_info:
    'Describe what your event is about. Who is performing or joining you and write everything down potential participants could be interested in',
  event_description_placeholder: 'Enter a full description of the event...',
  event_teaser: 'Teaser',
  event_teaser_info:
    'Write a short text that gives people a brief insight and makes them curious',
  event_teaser_placeholder: 'Enter a teaser about the event...',
  event_hash_tag: 'Search hashtags for your event',
  event_hash_tag_placeholder: 'Type hashtags here...',
  event_gallery: 'Event gallery',
  event_gallery_info:
    'Pictures make events attractive. So pick a few pictures that describe your event. The first picture is the cover picture',
  drag_drop_image: ' Drag and drop image here',
  event_title_info: 'Please add a descriptive title for your event.',
  event_title_placeholder: 'Please add a descriptive title for your event.',
  event_name_placeholder: 'Event name ...',
  event_start_time: 'Starting time: ',
  event_start_time_info: 'when will your event start?',
  event_end_time: 'Finishing time: ',
  event_end_time_info: 'when will your event finish?',
  event_primary_category: 'Primary category',
  event_primary_category_info:
    'Please choose the category your event fits in the most',
  event_primary_category_placeholder: 'Select primary category',
  event_location_name: 'Location name',
  event_location_name_placeholder: 'E.g. Starbucks',
  event_location_direction: 'Location direction',
  event_location_direction_info:
    'Describe how to get to the location. If you have a link to a map, you can also add it here',
  event_location_direction_placeholder:
    'Enter the direction to the location...',
  event_other_category: 'Other categories',
  event_other_category_info:
    'Please choose additional categories which also could be used to describe your event.',
  event_other_category_placeholder: 'Select categories',

  apply_button: 'Apply',
  reset_button: 'Reset',
  date_start: 'Start: ',
  date_end: 'End: ',
  event_create: 'Created: ',
  event_last_modified: 'Last modified ',

  order_by: 'Sort by',
  search_event_placeholder: 'Search by event name...',

  start_date_tooltip: 'Set starting time to now',
  clear_start_date_tooltip: 'Clear the starting time',
  end_date_tooltip: 'Set end time to a month from now',
  clear_end_date_tooltip: 'Clear the end time',
  create_base_on_existed_tooltip: 'Create based on this event',
  map_drag_marker_tooltip: 'Drag the marker to choose a location...',
  map_choose_location: 'Chosen location: ',
  menu_home: 'Home',
  menu_all_events: 'All Events',
  menu_register_event: 'Register Event',

  event_sum: 'Total event : ',
  search_event: 'search events',
  register_event: 'Register events',
  event_characteristics: 'Event characteristics',
  event_contact: 'Contact',

  error_event_fetching: 'Events fetching',
  error_meta_data_fetching: 'Meta data fetching',
  error_function_not_implemented: 'Function not implemented',
  link_input_error: 'the link has to start with',
  error_start_time: 'Finishing time cannot be before starting time',
  error_end_time: 'Finishing time cannot be equal to starting time',
  searched_result: 'Searched event was',
  error_not_found: ' not found',
  error_event_not_exist:
    'The event you are looking for does not exist. Check other events using our fabulous api.',
  error_register_own_event: 'Or you can register your own event.',
  error_primary_category_handling: 'Please select one primary category!',
  error_email_require: 'Missing Info',
  error_missing_info: 'Email address is required',
  toast_creates_event: 'Event Creation',
  toast_edit_event: 'Veranstaltung editieren',
  toast_copied_to_the_clip_board: 'Link has been copied to the clip board!',
  error_creates_event_try_again:
    'Event creation failed, please try again later!',
  error_token_expire: 'Token expired',
  error_token_expire_msg: 'Redirect to login page',
  error_user_permission: 'User Permission',
  error_no_log_in: 'You would need to log in to access the page.',

  user_registeration: 'User Login',
  photo_upload: 'Photo upload',
  upload_limit: 'Upload limit',
  photo_over_max: 'Only max of 10 image is allowed',
  success_toast: 'Operate successfully!',
  success_creates_event: 'Event created successfully!',
  success_edit_event: 'Event edited successfully!',
  log_in: 'Log in',
  log_out: 'Log out',
  toast_notice: 'Notice',
  info_toast_not_enough_data:
    'Time selection may be removed to see enough data',

  event_register_step_main_info: 'Main Info',
  event_register_step_descrption: 'Description',
  event_register_step_details: 'Details',
  event_register_step_characteristics: 'Characteristics',
  event_register_step_contact_info: 'Contact info',
  event_register_step_event_preview: 'Event preview',

  menu_indicator: 'Menu',
  next_indicator: 'Next',
  last_modified: 'Last modified',
  not_provided: 'Not provided!',
  none_selected: 'none selected',
  no_other_category: 'no other categories',
  only_coordinate_provided: 'Only coordinates are provided!',

  characteristic_is_prepare_for_rain: 'venue prepared for rain',
  characteristic_pet_friendly: 'Pet friendly',
  characteristic_kids_friendly: 'Kids friendly',
  characteristic_parking_avaliable: 'Parking available',
  characteristic_alcohol_allowed: 'Alcohol allowed',
  characteristic_public_transportation: 'Public transportation',
  characteristic_qualified_data: 'Qualified Data',
  characteristic_unqualified_data: 'Unqualified Data',
  characteristic_qualified_data_info:
    'check if the source of information is accurate and verified',
  charateristic_indoor: 'Indoor',
  charateristic_outdoor: 'Outdoor',
  charateristic_food: 'Food',
  charateristic_beverage: 'Beverage',
  charateristic_wheelchair: 'Wheelchair',
  charateristic_stroller: 'Stroller',
  characteristic_food_variety: 'Food variaties available',
  charateristic_no_food_selection: 'No food variaties selected',
  charateristic_no_target_group_selection: 'No targeted audience selected',

  attribute_title_ticket_price: 'Ticket price',
  attribute_ticket_free: 'free',
  attribute_age_no_limit: 'No limit',
  attribute_title_age_range: 'Age range',
  attribute_title_attendee_limit: 'Attendee limit',
  attribute_title_volume: 'Volume',
  attribute_title_social_ability: 'Socializing opportunity',
  attribute_social_ability_info:
    'Is it easy to get to know new people at your event?',
  attribute_public_transport_info:
    'Can you reach the event by public transport?',
  attribute_title_rare: 'Rare',
  attribute_title_intense: 'Intense',
  attribute_title_involvement: 'Involvement',
  attribute_involvement_info:
    'Here you can specify how much your visitors can actively participate in the event themselves. Maybe they can do exercises themselves, play along or train with them.',
  attribute_title_passive: 'Passive',
  attribute_title_active: 'Active',
  attribute_title_atmosphere: 'Atmosphere',
  attribute_atmosphere_info:
    'Here you can indicate whether the event is a contemplative, thoughtful event that helps you to calm down or if it is lively and about getting in contact with others.',
  attribute_title_contemplative: 'Contemplative',
  attribute_title_entertaining: 'Entertaining',
  attribute_title_target_group: 'Target group',
  attribute_title_venue_options: 'Venue option',
  attribute_title_food_beverages: 'Food and beverage',
  attribute_title_accessibility: 'Accessibility',
  attribute_ticket_info:
    'Please add the price range from the cheapest to the most expensive tickets',
  attribute_age_info: 'Please indicate for what age range the event caters to',
  attribute_attendee_limit_info:
    'If there is an attendee limit please put it here',
  attribute_volume_info:
    'Here you can indicate on which noise level participants can expect.',
  attribute_venue_options_info:
    'Choose if the event will take place indoors or outdoors',
  attribute_accessibility_info:
    'Please check the appropriate box if the event is accessible to strollers and/or wheelchairs.',

  contact_link_title_ticket: 'Ticket website',
  contact_link_title_website: 'Website link',
  contact_link_title_email: 'Email',
  contact_link_title_phone: 'Phone',
  contact_link_title_facebook: 'Facebook link',
  contact_link_title_ins: 'Instagram link',
  contact_link_title_google_map: 'Google map link',
  contact_link_title_twitter: 'Twitter link',

  event_detail_no_image: 'No uploaded images!',
  event_detail_navigation: 'More Info',
}

export default EnglishText
